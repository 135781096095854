import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  user = { email: "", password: "" };
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {}
  onLogin() {
    this.auth.doLogin(this.user).then(loggedIn => {
      this.router.navigateByUrl("/app"); //.navigate(['/login']);
    });
  }
}
