import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router } from "@angular/router";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: "/app/dashboard", title: "Dashboard", icon: "dashboard", class: "" },
  { path: "/app/fleet", title: "My Fleet", icon: "sports_handball", class: "" },
  {
    path: "/app/attendence",
    title: "Attendence",
    icon: "how_to_reg",
    class: ""
  },
  { path: "/app/all-tasks", title: "All Tasks", icon: "list_alt", class: "" },
  { path: "/app/surveys", title: "Surveys", icon: "list_alt", class: "" }
  // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
  // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
  // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  logout() {
    this.auth.doLogout().then(() => {
      this.router.navigateByUrl("/");
    });
  }
}
