import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import { BehaviorSubject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user: any;
  isLoggedin = new BehaviorSubject<boolean>(false);
  constructor(
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    const me = this;
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        await me.setUserProeprties();
      } else {
        this.isLoggedin.next(false);
      }
    });
  }
  getUserToken(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      if (this.isLoggedin.value === true) {
        const idToken = await firebase.auth().currentUser.getIdToken();
        resolve(idToken);
      } else {
        this.isLoggedin.subscribe(async val => {
          if (val === true) {
            const idToken = await firebase.auth().currentUser.getIdToken();
            resolve(idToken);
          }
        });
      }
    });
  }
  getUserName() {
    return this.user ? this.user.name : "";
  }
  getUserBrand() {
    return this.user ? this.user.brand : "";
  }
  isUserAdmin(): boolean {
    return this.user ? this.user.role === "admin" : false;
  }
  getUser() {
    return this.user;
  }
  isAuthenticated() {
    return this.getUserName() !== "";
  }
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      this.afAuth.auth.signInWithPopup(provider).then(
        async res => {
          await this.setUserProeprties();
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  doLogin(value): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(true);
          },
          err => reject(false)
        );
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut();
        window.location.reload();
        resolve();
      } else {
        reject();
      }
    });
  }
  setUserProeprties(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.firestore
          .doc(`users/${user.uid}`)
          .get()
          .subscribe((entity: any) => {
            if (entity.exists) {
              const data = entity.data();
              this.user = {
                // Name, email address, and profile photo Url
                name: user.displayName,
                email: user.email,
                photoUrl: user.photoURL,
                // The user's ID, unique to the Firebase project. Do NOT use this value to
                // authenticate with your backend server, if you have one. Use
                // FirebaseUser.getToken() instead.
                uid: user.uid,
                brand: data.brand,
                role: data.role
              };

              this.isLoggedin.next(true);
              resolve(this.user);
            } else {
              this.isLoggedin.next(false);
              reject("No User Found");
            }
          });
      } else {
        resolve(this.user);
      }
    });
  }
}
